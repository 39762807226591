<template>
  <div class="F-content-template detail" v-html="content">
  </div>
</template>

<script>
import { getPage } from '@/api/index';

export default {
  name: 'detail',
  data() {
    return {
      content: ''
    }
  },
  watch: {
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      let url = 'page-' + this.$route.query.id
      getPage(url).then( res => {
        this.content = res.data.content;
      })
    }
  },
}
</script>

<style lang="less">
.detail {
  @media (max-width: 767px) {
    /*屏幕小于 767 px*/
    img {
      max-width: 100%;
      font-size: 15px;
    }
  }
}
</style>

